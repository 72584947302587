.login-ctn {
  // background: url('../../assets/images/common/abc.png');
  background: var(--ion-background-body);
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  width: 100%;
  height: 100%;
  .MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root {
    padding: 0px !important;
  }
  .title-row {
    top: 15%;
    left: 50%;
    text-align: center;
    padding-top: 10px;
    .logo {
      max-width: var(--login-logo-xl-width);
      min-height: var(--login-logo-xl-height);
      margin-bottom: 10px;
    }
  }

  .login-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .login-ctn {
    // background: url('../../assets/images/common/abc.png');
    //background-color: var(--ion-background);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    height: 100%;
    .bottom-line {
      padding-top: 8px;
    }
    .title-row {
      padding-top: 30px;
    }
  }
}

@media screen and (max-width: 600px) {
  .login-ctn {
    // background: url('../../assets/images/common/abcmob.png') !important;
    // background-color: var(--ion-background) !important;
    background-color: var(--ion-color-primary);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (device-width: 360px) {
  .login-ctn {
    // background: url('../../assets/images/common/abcsmall.png');
    background-color: var(--ion-background);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    height: 100%;
  }
}

.new_login_ctn {
  display: flex;
  width: 100%;
  height: 100%;
  .login_image {
    border-radius: 30px 0px 0px 30px;
    width: 50%;
    .img_algin {
      width: 100%;
      height: 100%;
    }
  }
  .login_right_ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    background: #fff;
    height: 100%;
  }
}

.new_login_ctn_mob {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  .login_image {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    .img_algin {
      width: 100%;
      height: 100%;
    }
  }
  .moblie_image {
    height: 50%;
  }
  .mob_card {
    //height: 50% !important;
  }
  .login_right_ctn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: #fff;
    height: 100%;
  }
}

.login_btn_align {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  background: transparent;
  position: fixed;
  left: 45%;
  .MuiButton-containedPrimary {
    color: var(--ion-text-light)!important;
  }
}
