.MuiPopover-root {
  .MuiPopover-paper {
    background: var(--ion-popover-background);
    .MuiList-root,
    .MuiMenu-list {
      padding: 0px;
      .MuiListItem-root,
      .MuiMenuItem-root {
        color: var(--ion-text-color);
        font-size: 16px;
        height: 48px;
        padding-left: 16px;
        &:not(:last-child) {
          border-bottom: 1px solid
            rgba(var(--ion-text-color-contrast-rgb), 0.12);
        }
        &:hover {
          background: var(--ion-popover--hover-background);
        }
        &.Mui-selected {
          color: var(--ion-text-color-contrast);
          background: var(--ion-popover--hover-background);
          border-left: 4px solid var(--ion-color-primary);
        }
      }
    }
  }
}
