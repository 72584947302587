.change-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  width: 100%;

  .change-password-title {
    display: none;
  }

  @media screen and (max-width: 768px) {
    margin-top: 8px;
    background: #fff;

    .change-password-title {
      font-weight: 600;
      font-size: 18px;
      text-align: left;
      color: var(--ion-color-sec);
      width: 100%;
      display: flex;
      align-items: center;

      .change-pwd-icon {
        padding-right: 4px;
      }
    }
    .title-image-profile {
      width: 15px;
      background: var(--ion-color-sec);
      height: 30px;
      flex-shrink: 0;
      path {
        fill: var(--ion-color-primary);
      }
    }
  }

  .usr-input,
  .pwd-input {
    display: inline-grid;
    margin-top: 16px;
    width: 100%;

    .input-label {
      display: flex;
      align-items: center;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .MuiInputBase-root .MuiFormControl-root,
  .MuiOutlinedInput-root {
    background: #eaeef3 !important;
    border-radius: 30px;
    border: 1px solid #182ea6;
  }
  .MuiInputBase-root .MuiInputBase-input,
  .MuiInputBase-root .MuiOutlinedInput-input {
    padding: 10px 16px;
  }

  .cp-input .MuiInputBase-input {
    background: #eaeef3;
    border: 0;
    border-radius: 10px;
    border-radius: 30px;
  }

  .submit-form-btn {
    // margin-top: 20px !important;
    margin-bottom: 20px;
    width: 100%;
    // max-width: 341px;
    height: 48px;
    font-family: 'Kanit';
    font-weight: 500;
    font-size: 16px;
    border-radius: 30px;
    line-height: 19px;
    background: var(--ion-primary-gradient) !important;
    color: var(--ion-color-primary);

    @media screen and (max-width: 768px) {
      border-radius: 30px;
    }
    &:hover {
      //background: var(--ion-primary-gradient);
    }

    .MuiButton-label {
      color: var(--ion-text-light);

      &:hover {
        //background-color: var(--ion-color-primary);
      }
    }
  }

  .accept-terms-input {
    padding-top: 14px;
    color: #000;

    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;

      .MuiFormControlLabel-label {
        margin-left: 10px;
        color: var(--ion-profit-text-bet);
      }
    }

    .MuiFormHelperText-root {
      color: red;
    }

    .accept-terms-checkbox {
      color: var(--ion-profit-text-bet) !important;
      position: relative !important;
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}

.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}

.user-name,
.pwd-field {
  // max-width: 341px;
  height: 48px;
}

.input-tooltip {
  color: var(--ion-text-color);
  font-size: 20px;
  padding-left: 4px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    max-width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    max-width: 100%;
  }
}
