ion-button {
  &.back-btn {
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 8px;
    --padding-end: 12px;
    margin-right: 24px;
    --border-color: none;
    text-transform: none;
    ion-icon {
      height: 18px;
      width: 16px;
    }
    &.ion-color-secondary {
      --border-color: rgba(var(--ion-text-color-contrast-rgb), 0.1) !important;
      ion-icon {
        color: var(--ion-text-color);
      }
      .back-btn-text {
        color: var(--ion-color-secondary-contrast) !important;
      }
    }
  }
}
.home-page,
.my-sidebar,
.il-betslip-ctn {
  .MuiButtonBase-root {
    &.MuiButton-root {
      border-radius: 30px !important;
      .MuiButton-label {
        font-size: 18px !important;
        @media screen and (max-width: 720px) {
          font-size: 12px !important;
        }
        font-weight: 700 !important;
        white-space: nowrap !important;
      }
    }
    &.MuiButton-containedPrimary {
      background: var(--btn-primary-bg) !important;
      color: var(--ion-color-primary);
    }
    &.MuiButton-outlinedPrimary {
      border: 1px solid var(--gradient-color-primary) !important;
      color: var(--btn-outlined-primary-text) !important;
    }
    &.MuiButton-containedSizeSmall,
    &.MuiButton-outlinedSizeSmall {
      padding: 7px 14px !important;
    }
    &.MuiButton-containedSizeMedium,
    &.MuiButton-outlinedSizeMedium {
      padding: 10px 20px !important;
    }
    &.MuiButton-containedSizeLarge,
    &.MuiButton-outlinedSizeLarge {
      padding: 14px 28px !important;
      @media screen and (max-width: 720px) {
        padding: 7px 14px !important;
      }
    }
  }
}
