.login-form-page {
  .bottom-link {
    color: var(--btn-text);
    text-align: center;
    padding-top: 10px;
  }

  .red-text {
    color: red;
  }

  .title-row {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .logo {
      width: 130px;
    }
  }

  .login-form-ctn {
    min-width: 450px;
    max-height: unset;
    display: flex;
    flex-direction: column;
    background-color: var(--ion-background-color);
    border-radius: 30px;

    .card-title {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 54px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 50px;
    }

    .sign-up-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 50px;

      .sign-up-color {
        color: var(--ion-color-primary);
        text-decoration: underline;
      }
    }

    .page-title {
      display: flex;
      justify-content: center;
      font-size: 24px;
      line-height: 24px;
      color: var(--ion-color-secondary-contrast);
      font-family: var(--headers-font-family);
    }

    .usr-input,
    .code-input,
    .pwd-input {
      display: inline-grid;

      .input-labell {
        margin-bottom: 5px;
        color: #1a1d21;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 15px;
      }
    }

    .pwd-input {
      margin-top: 25px;
    }

    .form-control {
      margin: 10px 0;

      .MuiFormControl-root {
        background: #1f2e52 !important;

        .MuiInputBase-root {
          background: #1f2e52 !important;
          color: #fff !important;
          border-radius: 10px;
        }
      }
    }

    .MuiInputBase-root {
      border-radius: 10px;
      background-color: #fff !important;
      border: 0;
      border: 2px solid var(--ion-color-sec);;
      border-radius: 30px;
    }

    .MuiInputBase-input {
      color: #1a1d21;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 30px;
      border: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }

    .MuiIconButton-root {
      padding: 0;
      opacity: 0.4;
    }

    .login-err-msg {
      color: red;
      margin-top: 20px;
      text-align: center;
      max-width: unset;
    }

    .user-name,
    .pwd-field {
      max-width: 100%;
      height: 48px;

      .input-label {
        color: #fff !important;
      }
    }

    .login-demologin-btns {
      display: flex;
      justify-content: space-between;
      .login-form-btn {
        margin-top: 40px !important;
        margin-bottom: 20px;
        width: 48%;
        height: 50px;
        font-size: 13px;
        border-radius: 30px;
        // line-height: 19px;
        background: var(--ion-background-color);
        color: #1A1D21 ;
        font-weight: bold;
        border: 2px solid var(--ion-color-sec);
        margin-left: 5px;

        .MuiButton-label {
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          text-transform: capitalize;
        }
      }
    }

    .login_btn_bg {
      background: var(--ion-primary-gradient) !important;
      color: var(--ion-text-color) !important;
      border: none !important;
    }
    .login-demologin-btns {
      display: flex;
      justify-content: space-around;
      .login-form-btn-without-demologin {
        margin-top: 40px !important;
        margin-bottom: 20px;
        width: 100%;
        height: 50px;
        font-size: 13px;
        border-radius: 30px;
        // line-height: 19px;
        background: var(--ion-primary-gradient);
        color: var(--ion-color-primary) !important;
        font-weight: bold;

        .MuiButton-label {
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          text-transform: capitalize;
        }
      }
    }

    .forgot-pwd {
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }

    .bottom-link {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 6px;
      font-size: 16px;
      color: var(--btn-text);
      text-align: center;
      cursor: pointer;

      .register-link {
        color: var(--btn-text);
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login-form-page .login-form-ctn .user-name,
  .login-form-page .login-form-ctn .pwd-field {
    height: 40px;

    .MuiInputBase-root {
      height: 40px;

      .MuiInputBase-input {
        // height: 40px;
        padding: 10px 16px;
      }
    }
  }

  .login-ctn .title-row .logo {
    max-width: 160px !important;
    min-height: unset !important;
  }

  .login-form-page {
    width: 100vw;
    padding: 0px 16px;

    .bottom-link {
      margin-top: 8px;
      .home_btn{
        color: #fff !important;
      }
    }

    .login-form-ctn {
      .forgot-pwd-link {
        font-size: 12px;
        margin-bottom: 0;
      }
    }

    .title-row {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .logo {
        width: 130px;
      }
    }

    .login-form-ctn {
      min-width: 80% !important;
      max-width: unset !important;

      .login-form-btn {
        margin-top: 20px;
        height: 44px;
        border-radius: 8px;

        .MuiButton-label {
          font-size: 16px;
        }
      }

      .card-title {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 34px;
      }

      .sign-up-title {
        font-size: 14px;
        margin-bottom: 34px;
      }

      .usr-input,
      .pwd-input {
        .input-labell {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (device-width: 360px) {
  .login-form-page {
    .login-form-ctn {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (device-width: 411px) and (device-height: 823px) {
  .login-form-page {
    .login-form-ctn {
    }
  }
}

@media only screen and (device-width: 375px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
    }
  }
}

@media only screen and (max-device-width: 320px) {
  .login-form-page {
    .title-row {
      display: flex;
      justify-content: center;

      .logo {
        width: 130px;
      }
    }

    .login-form-ctn {
      min-width: 250px !important;
      max-width: 250px !important;

      .page-title {
        font-size: 16px;
        line-height: 16px;
      }

      .MuiInputBase-input {
        font-size: 13px;
        font-family: 'Kanit';
      }

      .user-name,
      .pwd-field {
        height: 38px;
      }

      // .user-name,
      // .pwd-field,
      // .login-form-btn {
      //   max-width: 250px;
      // }

      .login-form-btn {
        height: 30px;
        font-size: 13px;
      }
    }
  }
  .login-form-page
    .login-form-ctn
    .login-demologin-btns
    .login-form-btn
    .MuiButton-label {
    font-size: 14px;
  }
}

@media only screen and (max-device-width: 1023px) and (min-device-width: 769px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 550px !important;
      max-width: 550px !important;
      margin-top: 10rem;
      padding: 10px 18px !important;

      .page-title {
        font-size: 16px;
        line-height: 16px;
      }

      .MuiInputBase-input {
        font-size: 13px;
        font-family: 'Kanit';
      }

      .user-name,
      .pwd-field {
        height: 38px;
      }

      // .user-name,
      // .pwd-field,
      // .login-form-btn {
      //   max-width: 250px;
      // }

      .login-form-btn {
        height: 60px;
        font-size: 5px;
      }
    }
  }
}

@media only screen and (max-device-width: 440px) {
  .login-form-page {
    .login-form-ctn {
      .login-demologin-btns {
        display: flex;
        flex-direction: row;
        .login-form-btn {
          width: 100%;
          height: 35px;
          margin-top: 20px !important;
          margin-bottom: 10px !important;
          border-radius: 30px;
          .MuiButton-label {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.resgi {
  color: #1a1d21;
}

.reg_color {
  color: var(--gradient-color-primary);;
}

.home_btn {
  background: var(--ion-primary-gradient) !important;
  height: 50px;
  font-size: 13px;
  border-radius: 30px !important;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary) !important;
  font-weight: bold;
}
